import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import imgAskEmmCover from "../../assets/images/ask-emm-bg.png"
import imgMarsSurface from "../../assets/images/home/mars-surface.png"
import imgIcon1 from "../../assets/images/mission/about-mars/icon-(1).svg"
import imgIcon2 from "../../assets/images/mission/about-mars/icon-(2).svg"
import imgIcon3 from "../../assets/images/mission/about-mars/icon-(3).svg"
import imgIcon4 from "../../assets/images/mission/about-mars/icon-(4).svg"
import imgIcon5 from "../../assets/images/mission/about-mars/icon-(5).svg"
import imgEarth from "../../assets/images/mission/about-mars/earth.svg"
import imgMars from "../../assets/images/mission/about-mars/mars.svg"
import imgFactOne from "../../assets/images/home/fact-1-w.jpg"
import imgFactTwo from "../../assets/images/home/fact-2-w.jpg"
import imgFactThree from "../../assets/images/home/fact-3-w.jpg"
import imgFactFour from "../../assets/images/home/fact-4-w.jpg"
import imgFactFive from "../../assets/images/home/fact-5-w.jpg"
import imgFactSix from "../../assets/images/home/fact-6-w.jpg"
import imgFactSeven from "../../assets/images/home/fact-7-w.jpg"
import imgFactEight from "../../assets/images/home/fact-8-w.jpg"
import imgFactNine from "../../assets/images/home/fact-9-w.jpg"
import "./about-mars.css";

const AboutMarsPage = () => (
  <Layout>
    <SEO title="About Mars" />
    <main>
      <div className="uk-cover-container" data-uk-height-viewport="offset-top: true; offset-bottom: 40">
        <img src={imgAskEmmCover} alt="" data-uk-cover />
        <div className="uk-overlay uk-position-cover header-overlay"></div>
      </div>
      <section id="about-emm" className="negative-margin-top-300 relative">
            <div className="standard-container-p0 pv4 ph5-ns">
                <h2 className="relative uk-text-center">
                    ABOUT MARS
                <div className="text-underline-heavy" style={{marginLeft: "auto", marginRight: "auto"}}></div>
                </h2>
                <div className="ph4-ns">
                  <div className="uk-position-relative uk-visible-toggle uk-light" data-tabindex="-1" data-uk-slider="clsActivated: uk-transition-active; center: true ">
                    <div className="uk-position-relative">
                      <div className="uk-slider-container uk-light">
                      <ul className="uk-slider-items uk-grid uk-grid-small">
                          <li className="uk-width-1-1">
                              <div className="uk-panel pa4-ns animated-text-trigger">
                                  <div className="animated-image-container">
                                      <img src={imgFactOne} alt=""/>
                                  </div>
                                  <div className="uk-position-center uk-text-center">
                                      <h2 className="fact-slider-heading">
                                      Mars is the <br/>
                                        <span className="span-underline"> second smallest planet </span> <br/> in the solar system
                                      </h2>
                                      {/* <p uk-slider-parallax="x: 200,-200">Lorem ipsum dolor sit amet.</p> */}
                                  </div>
                              </div>
                          </li>
                          <li className="uk-width-1-1">
                              <div className="uk-panel pa4-ns">
                                  <div className="animated-image-container">
                                      <img src={imgFactTwo} alt=""/>
                                  </div>
                                  <div className="uk-position-center uk-text-center">
                                      <h2 className="fact-slider-heading">It’s the <br/> <span className="span-underline">fourth planet from the sun</span></h2>
                                  </div>
                              </div>
                          </li>
                          <li className="uk-width-1-1">
                              <div className="uk-panel pa4-ns">
                                  <div className="animated-image-container">
                                      <img src={imgFactThree} alt=""/>
                                  </div>
                                  <div className="uk-position-center uk-text-center">
                                      <h2 className="fact-slider-heading">
                                        <span className="span-underline">One day in Mars </span> <br/> is a little over 24 hours
                                      </h2>
                                      {/* <p uk-slider-parallax="x: 200,-200">Lorem ipsum dolor sit amet.</p> */}
                                  </div>
                              </div>
                          </li>
                          <li className="uk-width-1-1">
                              <div className="uk-panel pa4-ns">
                                  <div className="animated-image-container">
                                      <img src={imgFactFour} alt=""/>
                                  </div>
                                  <div className="uk-position-center uk-text-center">
                                      <h2 className="fact-slider-heading">
                                        <span className="span-underline">One Martian year </span> <br/> is 687 days
                                      </h2>
                                      {/* <p uk-slider-parallax="x: 200,-200">Lorem ipsum dolor sit amet.</p> */}
                                  </div>
                              </div>
                          </li>
                          <li className="uk-width-1-1">
                              <div className="uk-panel pa4-ns">
                                  <div className="animated-image-container">
                                      <img src={imgFactFive} alt=""/>
                                  </div>
                                  <div className="uk-position-center uk-text-center">
                                      <h2 className="fact-slider-heading">
                                      Mars is a <br/> <span className="span-underline"> rocky planet 
                                        </span>
                                      </h2>
                                      {/* <p uk-slider-parallax="x: 200,-200">Lorem ipsum dolor sit amet.</p> */}
                                  </div>
                              </div>
                          </li>
                          <li className="uk-width-1-1">
                              <div className="uk-panel pa4-ns">
                                  <div className="animated-image-container">
                                      <img src={imgFactSix} alt=""/>
                                  </div>
                                  <div className="uk-position-center uk-text-center">
                                    <h2 className="fact-slider-heading">
                                      Mars has
                                        <span className="span-underline"> two moons</span>
                                        <br/>
                                        Phobos and Demos
                                      </h2>
                                      {/* <p uk-slider-parallax="x: 200,-200">Lorem ipsum dolor sit amet.</p> */}
                                  </div>
                              </div>
                          </li>
                          <li className="uk-width-1-1">
                              <div className="uk-panel pa4-ns">
                                  <div className="animated-image-container">
                                      <img src={imgFactSeven} alt=""/>
                                  </div>
                                  <div className="uk-position-center uk-text-center">
                                      <h2 className="fact-slider-heading">
                                      Mars has a <br/>
                                        <span className="span-underline"> thin atmosphere</span>
                                      </h2>
                                  </div>
                              </div>
                          </li>
                          <li className="uk-width-1-1">
                              <div className="uk-panel pa4-ns">
                                  <div className="animated-image-container">
                                      <img src={imgFactEight} alt=""/>
                                  </div>
                                  <div className="uk-position-center uk-text-center">
                                      <h2 className="fact-slider-heading">
                                      Mars appears red because of <br/>
                                        <span className="span-underline"> iron minerals in its soil</span>
                                      </h2>
                                      {/* <p uk-slider-parallax="x: 200,-200">Lorem ipsum dolor sit amet.</p> */}
                                  </div>
                              </div>
                          </li>
                          <li className="uk-width-1-1">
                              <div className="uk-panel pa4-ns">
                                  <div className="animated-image-container">
                                      <img src={imgFactNine} alt=""/>
                                  </div>
                                  <div className="uk-position-center uk-text-center">
                                      <h2 className="fact-slider-heading">
                                      Mars has <br/>
                                        <span className="span-underline"> no global magnetic field </span> <br/> today
                                      </h2>
                                      {/* <p uk-slider-parallax="x: 200,-200">Lorem ipsum dolor sit amet.</p> */}
                                  </div>
                              </div>
                          </li>
                        </ul>

                        <div className="uk-hidden@s uk-light uk-text-center">
                           <a className="uk-position-small custom-slide-nav" href="#" data-uk-slidenav-previous data-uk-slider-item="previous">PREV</a>
                            <a className="uk-position-small custom-slide-nav" href="#" data-uk-slidenav-next data-uk-slider-item="next">NEXT</a>
                        </div>

                        <div className="uk-visible@s">
                            <a className="uk-position-center-left-out uk-position-small custom-slide-nav" href="#" data-uk-slidenav-previous data-uk-slider-item="previous">PREV</a>
                            <a className="uk-position-center-right-out uk-position-small custom-slide-nav" href="#" data-uk-slidenav-next data-uk-slider-item="next">NEXT</a>
                        </div> 
                      </div>      
                    </div>
                        {/* <div className="flex justify-center uk-margin">
                          <ul className="uk-slider-nav uk-flex-center uk-margin custom-dot-nav">
                            <li data-uk-slider-item="0"><a href="#">01</a></li>
                            <li data-uk-slider-item="1"><a href="#">02</a></li>
                            <li data-uk-slider-item="2"><a href="#">03</a></li>
                            <li data-uk-slider-item="3"><a href="#">04</a></li>
                            <li data-uk-slider-item="4"><a href="#">05</a></li>
                          </ul> 
                        </div>  */}
                  </div>
                </div>
            </div>
            <div className="standard-container standard-bg negative-margin-top-250" style={{zIndex: "-1"}}></div>
            <div className="large-container pb6">
            <div className="standard-container standard-bg">
              <div className="ph4-ns">
              <p className="mt4">Named by ancient Romans for their God of War because of its reddish colour that resembled blood, Mars is the fourth planet from the Sun. A cold, dusty and desert planet, Mars has a very thin atmosphere. It has seasonal variations, polar ice caps, weather, canyons, mountains, extinct volcanoes, and other features that can be found in our own planet.</p>
              <p>Mars is also the most explored extra-terrestrial body in our solar system. Humans have sent orbiters, probes, and landers to demystify the secrets of the red planet. While we have learnt a great deal from our past missions, there is a lot more to be learnt about the history, present and the future of Mars. </p>
              <p>So far, our search has provided us with ample evidence that Mars was once a much warmer and wetter planet, with a thicker, more substantial atmosphere. So, what happened over billions of years that led to Mars turning into an arid world? Studying, in-depth, the atmosphere of Mars is the only way to uncover the story, which could not only help humans go to Mars, but also better understand our planet and its future. </p>
              <div className="pv4">
                <div className="flex flex-column flex-row-ns pv3">
                    <div className="w-100 w-20-ns pt2">
                        <img src={imgIcon3} className="w-60" alt=""/>
                    </div>
                    <div className="w-100 w-80-ns pt3">
                        <p className="uk-text-lead text-highlight-brown uk-text-bold">
                        The Martian Atmosphere
                        </p>
                        <p className="uk-text-meta">
                        The thin atmosphere of Mars is mainly made up of carbon dioxide, nitrogen and argon gases. Mars is also frequently impacted by rogue objects from space like meteorites, asteroids and comets. The temperature on Mars can go from as high as 20 degrees Celsius to as low as -153 degree Celsius. The heat from the Sun escapes the Red Planet quite easily, and this difference in temperature can be experienced if you were on Mars. If you were to be standing on Mars' surface at noon on the equator, it would feel like spring at your feet (24 degrees Celsius) and winter at your head (0 degrees Celsius). Occasionally, Mars witnesses strong winds causing storms of dust which could potentially cover the entire planet for months.
                        </p>
                    </div>
                </div>
                <hr className="custom-hr" style={{borderColor: "black"}} />
                <div className="flex flex-column flex-row-ns pv3">
                    <div className="w-100 w-20-ns pt3">
                        <img src={imgIcon5} className="w-60" alt=""/>
                    </div>
                    <div className="w-100 w-80-ns pt3">
                        <p className="uk-text-lead text-highlight-brown uk-text-bold">
                        Size and distance
                        </p>
                        <p className="uk-text-meta">
                        Mars is only about half the size of Earth with a radius of 3,390 km. Mars is 1.5 astronomical units away from the Sun from an average distance of 228 million kilometres. One astronomical unit ( abbreviated as AU) is the distance between the Earth and the Sun.
                        </p>
                    </div>
                </div>
                <hr className="custom-hr" style={{borderColor: "black"}} />
                <div className="flex flex-column flex-row-ns pv3">
                    <div className="w-100 w-20-ns pt3">
                        <img src={imgIcon1} className="w-60" alt=""/>
                    </div>
                    <div className="w-100 w-80-ns pt3">
                        <p className="uk-text-lead text-highlight-brown uk-text-bold">
                        Structure
                        </p>
                        <p className="uk-text-meta">
                        At its centre, Mars has a dense core between 1,500 and 2,100 km in radius. It is made mostly of iron, nickel, and sulphur. The core is surrounded by a rocky mantle between 1,240 and 1,880 km thick and above that a crust made of iron, magnesium, aluminium, calcium and potassium. This crust is somewhere between 10 and 50 km deep.
                        </p>
                    </div>
                </div>
                <hr className="custom-hr" style={{borderColor: "black"}} />
                <div className="flex flex-column flex-row-ns pv3">
                    <div className="w-100 w-20-ns pt3">
                        <img src={imgIcon2} className="w-60" alt=""/>
                    </div>
                    <div className="w-100 w-80-ns pt3">
                        <p className="uk-text-lead text-highlight-brown uk-text-bold">
                        Surface
                        </p>
                        <p className="uk-text-meta">
                        Though Mars appears red, the surface of the planet is composed of several colours including gold, brown, and tan. The reason Mars appears reddish is due to iron oxidation — or rusting — in the rocks, regolith (Martian "soil"), and Mars dust. This dust gets swept up into the atmosphere and makes the planet look mostly red from a distance. 
                        </p>
                    </div>
                </div>
                <hr className="custom-hr" style={{borderColor: "black"}} />
                <div className="flex flex-column flex-row-ns pv3">
                    <div className="w-100 w-20-ns pt3">
                        <img src={imgIcon4} className="w-60" alt=""/>
                    </div>
                    <div className="w-100 w-80-ns pt3">
                        <p className="uk-text-lead text-highlight-brown uk-text-bold">
                        Moons
                        </p>
                        <p className="uk-text-meta">
                        Mars has two small moons, Phobos and Deimos, that may be captured asteroids. They’re potato-shaped because they have too little mass for gravity to make them spherical. The moons get their names from the horses that pulled the chariot of the Greek god of war, Ares. In ancient Greek, Phobos means “flight,” and Deimos means “fear.”                        </p>
                    </div>
                </div>   
                </div>
              </div>
              
              <div className="about-mars-table">
                
                <table className="uk-table uk-table-responsive  uk-table-divider uk-table-small">
                    <tbody className="text-white">
                        <tr style={{paddingBottom: "40px"}}>
                            <td>
                            <div className="animated-tab-head about-mars-tab-head uk-text-center uk-text-left@s">
                                <div>
                                    <h2 className="text-white uk-text-uppercase pv3">
                                    comparison between <br/> earth and mars
                                    </h2>
                                </div>
                            </div>
                            </td>
                            <td className="uk-text-center uk-text-left@s">
                                <img src={imgEarth} alt=""/>
                            </td>
                            <td className="uk-text-center uk-text-left@s">
                                <img src={imgMars} alt=""/>
                            </td>
                        </tr>
                        <tr>
                            <td className="text-highlight-brown">
                            AVERAGE DISTANCE FROM SUN
                            </td>
                            <td>
                            <span className="uk-hidden@s">Earth : </span>
                            149 million km
                            </td>
                            <td className="uk-text-center uk-text-left@s">
                            <span className="uk-hidden@s">Mars : </span>
                            228 million km
                            </td>
                        </tr>
                        <tr>
                            <td className="text-highlight-brown">
                            AVERAGE SPEED IN ORBITING SUN
                            </td>
                            <td>
                            <span className="uk-hidden@s">Earth : </span>
                            30 km per second
                            </td>
                            <td>
                            <span className="uk-hidden@s">Mars : </span>
                            23 km per second
                            </td>
                        </tr>
                        <tr>
                            <td className="text-highlight-brown">
                            DIAMETER
                            </td>
                            <td>
                            <span className="uk-hidden@s">Earth : </span>
                            12,755 km
                            </td>
                            <td>
                            <span className="uk-hidden@s">Mars : </span>
                            6,791 km
                            </td>
                        </tr>
                        <tr>
                            <td className="text-highlight-brown">
                            TITLE OF AXIS
                            </td>
                            <td>
                            <span className="uk-hidden@s">Earth : </span>
                            23.5 degrees
                            </td>
                            <td>
                            <span className="uk-hidden@s">Mars : </span>
                            25 degrees
                            </td>
                        </tr>
                        <tr>
                            <td className="text-highlight-brown">
                            LENGTH OF YEAR
                            </td>
                            <td>
                            <span className="uk-hidden@s">Earth : </span>
                            365.25 Days
                            </td>
                            <td>
                            <span className="uk-hidden@s">Mars : </span>
                            687 Earth Days
                            </td>
                        </tr>
                        <tr>
                            <td className="text-highlight-brown">
                            LENGTH OF DAY
                            </td>
                            <td>
                            <span className="uk-hidden@s">Earth : </span>
                            23 hours 56 minutes
                            </td>
                            <td>
                            <span className="uk-hidden@s">Mars : </span>
                            24 hours 37 minutes
                            </td>
                        </tr>
                        <tr>
                            <td className="text-highlight-brown">
                            GRAVITY
                            </td>
                            <td>
                            <span className="uk-hidden@s">Earth : </span>
                            2.66 times that of Mars
                            </td>
                            <td>
                            <span className="uk-hidden@s">Mars : </span>
                            0.375 times that of Earth
                            </td>
                        </tr>
                        <tr>
                            <td className="text-highlight-brown">
                            TEMPERATURE
                            </td>
                            <td>
                            <span className="uk-hidden@s">Earth : </span>
                            Average 14 degree Celsius
                            </td>
                            <td>
                            <span className="uk-hidden@s">Mars : </span>
                            Average -63 degree Celsius
                            </td>
                        </tr>
                        <tr>
                            <td className="text-highlight-brown">
                            ATMOSPHERE
                            </td>
                            <td>
                            <span className="uk-hidden@s">Earth : </span>
                            nitrogen, oxygen, argon, others
                            </td>
                            <td>
                            <span className="uk-hidden@s">Mars : </span>
                            mostly carbon dioxide, some water vapor
                            </td>
                        </tr>
                        <tr>
                            <td className="text-highlight-brown">
                            NUMBER OF MOONS
                            </td>
                            <td>
                            <span className="uk-hidden@s">Earth : </span>
                            1
                            </td>
                            <td>
                            <span className="uk-hidden@s">Mars : </span>
                            2
                            </td>
                        </tr>
                    </tbody>
                </table>
              </div>
            </div>         
          </div>  
      </section>
    </main>
  </Layout>
)

export default AboutMarsPage
